<template>
  <div>
    <validation-provider
      #default="{ errors }"
      name="TC Kimlik No"
      :rules="['3'].includes(userData.id_com_department)? 'required' : ''"
    >
      <b-form-group
        label="TC Kimlik No"
        label-for="tckn"
      >
        <cleave
          id="tckn"
          v-model="customer.tckn"
          class="form-control"
          :raw="false"
          :options="options.tckn"
          placeholder="TC Kimlik No"
          :readonly="isReadonly"
          @keyup="tcValidate"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>
    <template v-if="tcControl.status">
      <b-alert
        :show="tcControl.message? true : false"
        :variant="tcControl.level === 5? 'success' : 'warning'"
      >
        <div class="alert-body text-center">
          <b-form-rating
            :value="tcControl.level"
            variant="warning"
            readonly
            size="sm"
            no-border
            class="bg-transparent"
            inline
          />
          <div>
            {{ tcControl.message }}
          </div>
          <div class="font-small-2">
            {{ tcControl.level >= 4 ? '' : 'Doğrulama seviyesi düşük' }}
          </div>
        </div>
      </b-alert>
    </template>
    <template v-else-if="tcControl.status === false">
      <b-alert
        show
        variant="danger"
      >
        <div class="alert-body text-center">
          <div :class="tcControl.id? 'mb-1' : ''">
            {{ tcControl.message }}
          </div>
          <b-button
            v-if="tcControl.id"
            :to="'/customers/view/' + tcControl.id"
            variant="danger"
            size="sm"
            target="_blank"
          >
            Müşteri Görüntüle
          </b-button>
        </div>
      </b-alert>
    </template>
  </div>
</template>

<script>
import {
  BFormGroup, BAlert, BFormRating, BButton,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import { localize, ValidationProvider } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'Tckn',
  components: {
    ValidationProvider,
    BFormGroup,
    Cleave,
    BAlert,
    BFormRating,
    BButton,
  },
  props: {
    isReadonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      required,
      options: {
        tckn: {
          blocks: [11],
          numericOnly: true,
        },
      },
    }
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
    tcControl() {
      return this.$store.getters['customers/tcControl']
    },
  },
  watch: {
    customer: {
      deep: true,
      handler(val) {
        if (val.tckn) {
          if (val.tckn.length === 11) {
            this.tcValidate()
          }
        }
      },
    },
  },
  created() {
    localize('tr')
  },
  methods: {
    tcValidate() {
      this.$store.dispatch('customers/tcValidate', { tckn: this.customer.tckn, id: this.customer.id })
        .then(response => {
          if (response.level) {
            this.customer.tckn_level = response.level
          }
        })
    },
  },
}
</script>
