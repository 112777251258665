<template>
  <div>
    <validation-provider
      #default="{ errors }"
      name="Vergi No"
      :rules="['3'].includes(userData.id_com_department)? 'required' : ''"
    >
      <b-form-group
        label="Vergi No"
        label-for="tax_number"
      >
        <cleave
          id="tax_number"
          v-model="customer.tax_number"
          class="form-control"
          :raw="false"
          :options="options.tckn"
          placeholder="Vergi No"
          :readonly="isReadonly"
          @keyup="taxNoValidate"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>
    <template v-if="taxNoControl.status">
      <b-alert
        :show="taxNoControl.message? true : false"
        :variant="taxNoControl.level === 5? 'success' : 'warning'"
      >
        <div class="alert-body text-center">
          <b-form-rating
            :value="taxNoControl.level"
            variant="warning"
            readonly
            size="sm"
            no-border
            class="bg-transparent"
            inline
          />
          <div>
            {{ taxNoControl.message }}
          </div>
          <div class="font-small-2">
            {{ taxNoControl.level !== 5 ? 'Doğrulama seviyesi orta' : '' }}
          </div>
        </div>
      </b-alert>
    </template>
    <template v-else-if="taxNoControl.status === false">
      <b-alert
        show
        variant="danger"
      >
        <div class="alert-body text-center">
          <div :class="taxNoControl.id? 'mb-1' : ''">
            {{ taxNoControl.message }}
          </div>
          <b-button
            v-if="taxNoControl.id"
            :to="'/customers/view/' + taxNoControl.id"
            variant="danger"
            size="sm"
            target="_blank"
          >
            Müşteri Görüntüle
          </b-button>
        </div>
      </b-alert>
    </template>
  </div>
</template>

<script>
import {
  BFormGroup, BAlert, BFormRating, BButton,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import { localize, ValidationProvider } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'Tckn',
  components: {
    ValidationProvider,
    BFormGroup,
    Cleave,
    BAlert,
    BFormRating,
    BButton,
  },
  props: {
    isReadonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      required,
      options: {
        tckn: {
          blocks: [10],
          numericOnly: true,
        },
      },
    }
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
    taxNoControl() {
      return this.$store.getters['customers/taxNoControl']
    },
  },
  watch: {
    customer: {
      deep: true,
      handler(val) {
        if (val.tax_number) {
          if (val.tax_number.length === 10) {
            this.taxNoValidate()
          }
        }
      },
    },
  },
  created() {
    localize('tr')
  },
  methods: {
    taxNoValidate() {
      this.$store.dispatch('customers/taxNoValidate', { tax_number: this.customer.tax_number, id: this.customer.id })
        .then(response => {
          if (response.level) {
            this.customer.tax_number_level = response.level
          }
        })
    },
  },
}
</script>
